import * as React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Nav } from '../../components';
import { WorkSectionHeader } from '../../components';

import carGif from '../../images/work/car/landing.gif';
import GlobalLayout from '../../layouts/GlobalLayout';

const Car = () => {
  const imgStyleOutline = {
    boxShadow: '0px 4px 32px #00000015',
    maxWidth: '1280px',
    // marginLeft: '50vw',
    placeSelf: 'center',
    marginTop: '3.33em',
  };
  return (
    <GlobalLayout>
      <Nav />
      <WorkSectionHeader
        workType='Concept'
        workName='$CAR by Shl0ms'
        what='Shl0ms blew up a lambo &amp; auctioned the physically fractionalized pieces as NFTs &amp; I designed my own version of the auction interface as a concept.'
        who='Side Project'
        role='Product Design'
        shoutout='Shl0ms'
      />

      <ShowcaseContainer style={{ backgroundColor: 'black' }}>
        <img
          alt='lolololololololololololololololol'
          style={{ imgStyleOutline }}
          src={carGif}
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleOutline}
          src='../../images/work/car/carousel.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleOutline}
          src='../../images/work/car/bid.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleOutline}
          src='../../images/work/car/outbid.png'
        />
      </ShowcaseContainer>
    </GlobalLayout>
  );
};

const ShowcaseContainer = styled.section`
  display: flex;
  flex-flow: column;
  padding: 3.33em 0;
  justify-content: center;
`;

export default Car;
